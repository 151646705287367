import React from "react";
import {Col, Row, Container, Table } from 'react-bootstrap';

const FurtherDetails=(props)=>{
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
     }
     var psr1 = ""
     var psr2 =""
    if(props.letting_info?.deposit?.amount != 0 && props.letting_info && props.property_search_type == "lettings") {
        if(props.letting_info?.deposit?.type == "weeks") {
            psr1 = props.property_price * 12 / 52 * props.letting_info?.deposit?.amount;
        } else if ((props.letting_info?.deposit?.type == "months") || (props.letting_info?.deposit?.type == "month")) {
            psr1 = props.property_price * props.letting_info?.deposit?.amount;
        }else if (props.letting_info?.deposit?.type == "fixed") {
            psr1 = props.property_price;
        }
        psr2 = parseInt(psr1);
    }
    return(
        <>
        {(props.selling_info?.tenure?.type) || (props.extra?.councilTax) || (props.extra?.groundRent) || (props.extra?.serviceCharge) || (props.letting_info?.deposit) || (props.letting_info?.minimumTerm) ?
        <section className="further-details">    
            <Container id="detailsFurther">
                <h2>Further details</h2>
                <Row>
                {props.selling_info?.tenure?.type && props.property_search_type == "sales" &&
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Tenure:</h3>
                    <span>{titleCase(props.selling_info?.tenure?.type)}</span>
                </Col>
                }
                {props.extra?.councilTax &&
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Council Tax Band:</h3>
                    <span>Band {props.extra?.councilTax}</span>
                </Col>
                }
                {props.letting_info?.deposit?.amount != 0 && props.letting_info && props.property_search_type == "lettings" &&
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Security Deposit:</h3>
                    <span>£{psr2.toLocaleString()}</span>
                    {/* <span className="sm-small">{props.letting_info?.deposit?.type}</span> */}
                </Col>
                }
                {props.letting_info?.minimumTerm != 0 && props.letting_info && props.property_search_type == "lettings" &&
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Minimum Term of Tenancy:</h3>
                    <span>{props.letting_info?.minimumTerm}</span>
                    {/* <span className="sm-small">{props.letting_info?.deposit?.type}</span> */}
                </Col>
                }
                
                {props.extra?.groundRent != 0 && props.extra?.groundRent && 
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Ground Rent:</h3>
                    <div className={props.extra?.groundRentComment?"wrap-comment":""}>
                    <span>£{props.extra?.groundRent}</span>
                    <span className="sm-small">{props.extra?.groundRentComment?" ("+props.extra?.groundRentComment+")":""}</span>
                    </div>
                </Col>
                 }
                {/* <Col md={4}><h3>Ground Rent Comment:</h3></Col> */}
                {props.extra?.serviceCharge != 0 && props.extra?.serviceCharge &&  
                <Col md={6} lg={3} className="detail-wp-info">
                    <h3>Service Charge:</h3>
                    <div className={props.extra?.serviceChargeComment?"wrap-comment":""}>
                    <span>£{props.extra?.serviceCharge}</span>
                    <span className="sm-small">{props.extra?.serviceChargeComment?" ("+props.extra?.serviceChargeComment+")":""}</span>
                    </div>
                </Col>
                }
                {/* <Col md={4}><h3>Service Charge Comment:</h3></Col> */}
                </Row>
            </Container>    
        </section>
        :""}
        </>
    )
}
export default FurtherDetails
