import React, { useState, useEffect } from "react";
import gql from "graphql-tag"
import axios from 'axios';
import logoBlack from "../images/mg-logo-black.svg";
import Helmet from 'react-helmet'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../pages/404';

import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import Booking from "../components/PropertyDetails/Booking/Booking"
import Owners from "../components/PropertyDetails/Owners/Owners"
import FurtherDetails from "../components/PropertyDetails/FurtherDetails"
import Calculator from "../components/PropertyDetails/Calculator/Calculator"
import Information from "../components/PropertyDetails/Information/Information"
import Similar from "../components/PropertyDetails/Similar/Similar"
import SEO from "../components/seo-details"
import { getPidFromUrl } from "../utils"
import { UserObjectStoreProvider, useUserObjectState, useAuthState } from "@myaccountServices";

const BookingWrapper = (props) => {
  const { state, services } = useUserObjectState()
  const { services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      // on page load get the users saved objects
      services.getUserobject({ type: "property", state:true })
    }
  }, [])

  return (
    <Booking {...props} />
  )
}


const PROPERTY_DETAILS =  gql`
  query PropertyDetails ($url: String!){
    properties(where:{id: $url}) {
      search_type
      price
      price_qualifier
      display_address
      title
      bedroom
      bathroom
      building
      extra
      selling_info
      letting_info
      special
      parking
      floorplan
      crm_negotiator_id
      images
      virtual_tour
      status
      latitude
      longitude
      crm_negotiator_id
      address
      owners
      description
      accommodation_summary
      noticed
      brochure
    }
  }
`;



const PropertyDetail = (props) => {

  const [property_details, setPropertyData] = useState('');
  const [loading, setloading] = useState(true);
  const [metaTitle, setmetaTitle] = useState('');
  const [metaDesc, setmetaDesc] = useState('');
  const [images1, setimages1] = useState([]);
  const [mapview, setMapView] = useState('location');

  const maphandleChange = (e) =>{
    if(e != '') {
    setMapView(e)
    }
    else {
    setMapView('location');
    }
  }

  var myMetaTitle = "";
  var myMetaDesc = "";

  if( props?.pageContext?.title ) {
    myMetaTitle = props.pageContext.title + ' in ' + props.pageContext.display_address;
    myMetaDesc = 'Book a viewing for this ' + props.pageContext.title + ' in ' +  props.pageContext.display_address + ' with Martyngerrard at £' + props.pageContext.price.toLocaleString() +'. Contact Martyngerrard, and get assisted for all your property needs.';
  }

  // similar properties
  const [propItems, setPropItems] = useState([])
  const getitems = async url => {
		try {
			const { data } = await axios.get(url, {})
			if (data && data.length > 0) {
				setPropItems(data)
			}
		} catch (error) {
			console.error(error)
		}
	}
  // similar properties

  useEffect(() => {
    getPropertyData();
    // similar properties
    let pid = getPidFromUrl()
    let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + pid;
		getitems(url)
    // similar properties
  }, []);
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let property_slug = pageurl.split('-');
    let idsplit = property_slug[property_slug.length-1];
    let id = getPidFromUrl()

    // const [purl , setUrl] = useState(pageurl)
    // const { loading, error, data:property_details } = useQuery(PROPERTY_FOR_SALE, {
    // variables: { url: id }});
    const getPropertyData = async () => {
      const property_details = await axios.get(`${process.env.GATSBY_STRAPI_SRC}/properties/${id}`).then((result) => {
        if(result.data){
          setPropertyData(result.data);
          sessionStorage.setItem('data', JSON.stringify(result.data))
          sessionStorage.setItem('property_url', pageurl)
          setmetaTitle((result.data.title).toLowerCase() + ' in ' + result.data.display_address)
          setmetaDesc('Book a viewing for this ' + result.data.title + ' in ' +  result.data.display_address + '. Contact our property specialists for more information about the property.')
          var imagesArr = []
          for(var i=0; i < result.data.images.length; i++){
            imagesArr.push(result.data.images[i].url)
          }
          setimages1(imagesArr)
          setloading(false)
        }
      }).catch((err) => {
        setloading(false)
        //console.log('property_details error', err.response.status);
        setPropertyData('NotFound');
      });
    };

    let processedImages = JSON.stringify({});
    if (property_details?.imagetransforms?.images_Transforms) {
        processedImages = property_details.imagetransforms.images_Transforms;
    }
    let processedImagesFloorplan = JSON.stringify({});
    if (property_details?.imagetransforms?.floorplan_Transforms) {
        processedImagesFloorplan = property_details.imagetransforms.floorplan_Transforms;
    }
    var metaTitleNew = metaTitle//.replace("bedroom", "Bedroom").replace("apartment", "Apartment").replace("house", "House").replace("bungalow", "Bungalow").replace("townhouse", "Townhouse").replace("sale", "Sale").replace("rent", "Rent");

    myMetaTitle = myMetaTitle.replace("bedroom", "Bedroom").replace("apartment", "Apartment").replace("house", "House").replace("bungalow", "Bungalow").replace("townhouse", "Townhouse").replace("sale", "Sale").replace("rent", "Rent");

    if (loading) return (
        // <div className="static-news gql-loading-screen">
        //     <Container>
        //     <img loading="lazy" className="logo-white" src={logoBlack} alt="logo"/>
        //       <div className="gql-loading">Loading ...</div>
        //     </Container>
        // </div>
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <div className="new-loader-anime"></div>
              <img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
    );

    let crm_id = property_details.crm_id.split('-');
    let crm_id_last = crm_id[crm_id.length - 1];

    return (
    	<>
      {
        property_details !== "NotFound" ?
        <UserObjectStoreProvider>
        <Helmet
            bodyAttributes={{
                class: 'property-details-page'
            }}
        />
         <HeaderTwo />
         <SEO title={metaTitle ? metaTitleNew : ''} description={metaDesc} location={props.location} />
         <div class="property-reference" style={{visibility: "hidden", display: "none"}}>{crm_id_last}</div>
          <div className="content propery-page">
            <BookingWrapper
              property_search_type={property_details.search_type}
              property_price={property_details.price}
              price_qualifier={property_details.price_qualifier}
              address_details={property_details.display_address}
              property_title={property_details.title}
              property_bedroom={property_details.bedroom}
              property_bathroom={property_details.bathroom}
              property_building={property_details.building}
              property_special={property_details.special}
              property_parking={property_details.parking}
              property_floorplan={property_details.floorplan}
              property_epc={property_details.epc}
              negotiator_details={property_details.crm_negotiator_id}
              property_images={property_details.images}
              property_video={property_details.virtual_tour}
              property_status={property_details.status}
              crm_negotiator_id={property_details.crm_negotiator_id}
              id={property_details._id}
              office_id={property_details.office_crm_id}
              processedImages={processedImages}
              processedImagesFloorplan={processedImagesFloorplan}
              brochure={property_details.brochure}
              crm_id={property_details.crm_id}
              maphandleChange={maphandleChange}
            />
            <FurtherDetails property_price={property_details.price}
              property_search_type={property_details.search_type} extra={property_details.extra} selling_info={property_details.selling_info} letting_info={property_details.letting_info}/>
            <Owners
              latitude={property_details.latitude}
              longitude={property_details.longitude}
              postcode={property_details.address && property_details.address.postcode.split(" ")[0]}
              property_owners_love={property_details.owners}
              property_description={property_details.description}
              property_accommodation_summary={property_details.accommodation_summary}
              property_noticed={property_details.noticed}
              property_owners_image={property_details.images}
              property_videotour={property_details.virtual_tour}
              vtouraddress_details={property_details.display_address}
              id={property_details._id}
              processedImages={processedImages}
              processedImagesFloorplan={processedImagesFloorplan}
              mapview={mapview}
            />
            <Calculator price={property_details.price} type="residential" property_search_type={property_details.search_type} title={property_details.display_address}/>

            <Information />

            {propItems.length != 0 &&
            <Similar
              data={propItems.length > 2 ? propItems.slice(0, 3) : propItems}
              Title={`Similar Properties near ${property_details.address.address1}, London`}
              id={property_details._id}
              processedImages={processedImages}
              processedImagesFloorplan={processedImagesFloorplan}
              postcode={property_details.address && property_details.address.postcode.split(" ")[0]}
            />
            }

            <CanHelp />
          </div>
          <Footer popularSearch='propertydetails' propertydetails={property_details}/>
          </UserObjectStoreProvider>
        :
        <Router>
          <Switch>
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      }
      </>
    )

}

export default PropertyDetail
